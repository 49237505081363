import { Injectable, EventEmitter, Output } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { BaseHttpService } from './http.service';
import { environment } from '../../environments/environment';
import { ChatRoomViewModel, ChatMessageViewModel } from '../../_customer/sidenav-right/_shared/models/chat.models';
import { AranSessionService } from './session.service';
import { NotificationViewModel } from '../models/notification.model';

@Injectable()
export class AranApplicationHubService {

    private _hubConnecting: boolean = false;
    private _hubConnectionAgentCount: number = 30;
    private _hubConnection: signalR.HubConnection | undefined;

    @Output() public onApplicationHubConnected: EventEmitter<any> = new EventEmitter();
    @Output() public onApplicationHubDisconnected: EventEmitter<any> = new EventEmitter();

    /// CLIENT <== SERVER
    @Output() public onNotificationReceive: EventEmitter<NotificationViewModel> = new EventEmitter(null);
    @Output() public onDocumentUploadReceive: EventEmitter<NotificationViewModel> = new EventEmitter(null);
    @Output() public onChatRoomChanges: EventEmitter<ChatRoomViewModel> = new EventEmitter(null);
    @Output() public onChatMessageReceive: EventEmitter<ChatMessageViewModel> = new EventEmitter(null);

    @Output() public onMessageReceive: EventEmitter<number> = new EventEmitter(null);
    @Output() public onAlertReceive: EventEmitter<number> = new EventEmitter(null);
    @Output() public onUnreadedMessages: EventEmitter<number> = new EventEmitter(null);
    @Output() public onUnreadedAlerts: EventEmitter<number> = new EventEmitter(null);

    constructor(
        private _httpService: BaseHttpService,
        private _sessionService: AranSessionService
    ) {
        // Controllo se websocket è off, se è off provo a riavviarlo
        setInterval(() => {
            try {
                this._hubConnectionAgentCount--;
                if (this._hubConnectionAgentCount == 0) {
                    this._hubConnectionAgentCount = 30;
                    //this.start();
                }
            } catch (e) { console.error(e); }
        }, 1000);

        // Registro gli eventi così il notificationHubService riesce
        // ad avviarsi/stopparsi da solo alla login/logout utente
        //this.start();
        this._sessionService.onUserLogin.subscribe((data: any) => { /*this.start();*/ });
        this._sessionService.onUserLogout.subscribe((data: any) => { /*this.stop();*/ });
    }


    public start() {
        return;
        //if (this.hubConnected() || this._hubConnecting) return;
        //if (this._sessionService.getToken() && this._sessionService.getToken() !== '') {
        //    this._hubConnecting = true;
            //this._hubConnection = new signalR.HubConnectionBuilder()
            //    .withUrl(`${environment.websocketURI}/applicationhub`, {
            //        skipNegotiation: true,
            //        transport: signalR.HttpTransportType.WebSockets,
            //        accessTokenFactory: () => this._sessionService.getToken()
            //    })
            //    .configureLogging(signalR.LogLevel.Error)
            //    .build();
            //this._hubConnection.start()
            //    .then(() => {
            //        this._hubConnecting = false;
            //        this.onApplicationHubConnected.emit();
            //        console.info('ApplicationHub connected');
                    
            //        this.notificationGetList();

            //        /// CLIENT <== SERVER
            //        this._hubConnection.on('unreadedNotifications', (res: { unreadedMessages: number, unreadedAlerts: number }) => {
            //            this.onUnreadedMessages.emit(res.unreadedMessages);
            //            this.onUnreadedAlerts.emit(res.unreadedAlerts);
            //        });
            //        this._hubConnection.on('newNotificationInbox', (res: { message: number, alert: number}) => {
            //            if (res.message > 0) this.onMessageReceive.emit(res.message);
            //            if (res.alert > 0) this.onAlertReceive.emit(res.alert);
            //        });

            //        // this._hubConnection.on('notificationHandler', (viewModel: NotificationViewModel) => { this.onNotificationReceive.emit(viewModel); });
            //        // this._hubConnection.on('documentUploadHandler', (viewModel: NotificationViewModel) => { this.onDocumentUploadReceive.emit(viewModel); });
            //        // this._hubConnection.on('newRoomHandler', (viewModel: ChatRoomViewModel) => { this.onChatRoomChanges.emit(viewModel); });
            //        // this._hubConnection.on('newRoomMessageHandler', (viewModel: ChatMessageViewModel) => { this.onChatMessageReceive.emit(viewModel); }); */
            //    })
            //    .catch(err => {
            //        this._hubConnecting = false;
            //        console.error(err);
            //    });
        //}
    }
    public stop() {
        if (this.hubConnected() && this._hubConnection) {
            this._hubConnection.stop();
            this.onApplicationHubDisconnected.emit();
            console.info('ApplicationHub disconnected');
        }
    }
    public hubConnected(): boolean { return false; /*(this._hubConnection && this._hubConnection.state == signalR.HubConnectionState.Connected);*/ }


    /// NOTIFICATION
    /// CLIENT ==> SERVER
    public notificationGetList() {
        if (!this.hubConnected()) return new Promise(() => { });
        return this._hubConnection.invoke('unreadedNotifications').catch(err => { console.error(err); /*debugger;*/ })
        // return this._hubConnection.invoke('notificationGetList').catch(err => { console.error(err); /*debugger;*/ })
    }
    public notificationRemove(ID: number) {
        if (!this.hubConnected()) return new Promise(() => { });
        return this._hubConnection.invoke('notificationRemove', ID).catch(err => { console.error(err); /*debugger;*/ });
    }

    /// CHAT
    /// CLIENT ==> SERVER
    public chatRoomGetList(): Promise<ChatRoomViewModel[]> { return this._hubConnection.invoke('chatRoomGetList').catch(err => { console.error(err); }); }
    public chatRoomGetDetails(roomID: number): Promise<ChatRoomViewModel> { return this._hubConnection.invoke('chatRoomGetDetails', roomID).catch(err => { console.error(err); }); }
    public chatRoomGetMessages(roomID: number): Promise<ChatMessageViewModel[]> { return this._hubConnection.invoke('chatRoomGetMessages', roomID).catch(err => { console.error(err); }); }
    public chatRoomPushMessage(model: ChatMessageViewModel): Promise<ChatMessageViewModel> {
        // clono l'oggetto e annullo l'avatar per migliorare prestazioni
        // ed evitare errori di comunicazione via WS
        var message = { ...model };
        if (message.author) message.author.avatarUrl = '';
        return this._hubConnection.invoke('ChatRoomPushMessage', message).catch(err => { console.error(err); });
    }

    public chatCreateRoom(model: ChatRoomViewModel): Promise<number> {
        // clono l'oggetto e annullo l'avatar per migliorare prestazioni
        // ed evitare errori di comunicazione via WS
        var room = { ...model };
        room.roomAvatar = '';
        for (let item of room.partecipants)
            item.avatarUrl = '';
        return this._hubConnection
            .invoke('ChatCreateRoom', room)
            .catch(err => { console.error(err); });
    }
}
