
export const auth_token_key: string = 'auth_token';
export const renew_auth_token_key: string = 'auth_token_renew';

export class TokenBase {
    public aud: string;
    public culture: string;
    public exp: number;
    public iat: number;
    public iss: string;
    public jti: string;
    public nbf: number;
    public sub: string;

    public UserClaimsRole: string;
    public ANAGID: string;
    public ROLE_ANAGID: string;
    public ROLE_GROUPID: string;

    public expiration(): Date {
        const d = new Date(0);
        if (!this.exp || this.exp <= 0) {
            return d;
        }
        else {
            d.setUTCSeconds(this.exp);
            return d;
        }
    }
}

export const getTokenExpDateFromUTCOffset = function (exp: number): Date {
    const d = new Date(0);
    if (!exp || exp <= 0) {
        return d;
    }
    else {
        d.setUTCSeconds(exp);
        return d;
    }
}