import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { AranApplicationHubService } from 'src/@aran/services/application-hub.service';
import { AranCookieService } from 'src/@aran/services/cookie.service';
import { BaseHttpService } from 'src/@aran/services/http.service';
import { AranSessionDataService } from 'src/@aran/services/session.data.service';
import { AranSessionService } from 'src/@aran/services/session.service';
import { AranTranslateService } from 'src/@aran/services/translate.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/it/all';

import { locale as global_locale_IT } from './../app/_shared/i18n/it-IT';
import locale_de from '@angular/common/locales/de';
import locale_it from '@angular/common/locales/it';

registerLocaleData('de-DE');
registerLocaleData(locale_it,'it-IT');

const routes: Routes = [
    {
        /// CUSTOMER AREA
        path: 'customer-area',
        loadChildren: () => import('src/_customer/customer-layout.module').then(m => m.CustomerLayoutModule),
    },
    {
        /// PUBLIC
        path: '',
        loadChildren: () => import('src/_public/public-layout.module').then(m => m.PublicLayoutModule),
    },
    {
        path: '**',
        redirectTo: '/login',
    },
];

@NgModule({
    declarations: [
        AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })], //, preloadingStrategy: PreloadAllModules
    providers: [
        BaseHttpService,
        AranSessionService,
        AranSessionDataService,
        AranApplicationHubService,
        AranCookieService,
        {
            provide: LOCALE_ID,
            useFactory: (translate: AranTranslateService) => { return translate.currentLanguage(); },
            deps: [AranTranslateService]
            //useValue: navigator.language
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (translate: AranTranslateService) => { return translate.currentLanguage(); },
            deps: [AranTranslateService]
            //useValue: navigator.language
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }],
    bootstrap: [
        AppComponent]
})
export class AppModule {

    constructor(
        private _translateService: AranTranslateService
    ) {
        this._translateService.loadTranslations(global_locale_IT);

        if (environment.production) {
            var style = 'font: bold 12px \'Courier New\'; color: green;';
            console.log('%c// DIGONOS', style);
            console.log('%c// https://www.digonos.com', style);
            console.log('%c// info@digonos.com', style);
            console.log('%c// Copyright ' + new Date().getFullYear(), style);
            console.log('');
        }
    }
}
