
export class Login2FAViewModel {
    userName: string = null;
    twoFactorProvider: string = null;
    twoFactorCode: string = null;
    culture: string = null;
    rememberMe: boolean = false;

    constructor() { }
}

export class AuthApp2FAQRCodeViewModel {
    public sharedKey: string = null;
    public authenticatorUri: string = null;

    constructor() { }
}