import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILocale } from '../models/locale.interface';

@Injectable({ providedIn: 'root' })
export class AranTranslateService {

    public defaultCulture: En_CultureCode = En_CultureCode.it_IT;
    public supportedCultures: CultureModel[] = [
        //<CultureModel>{ code: En_CultureCode.en_GB, abbrText: 'En', text: 'English' },
        <CultureModel>{ code: En_CultureCode.de_DE, abbrText: 'De', text: 'Deutsch' },
        <CultureModel>{ code: En_CultureCode.it_IT, abbrText: 'It', text: 'Italiano' },
    ];
    public defaultCultureModel: CultureModel = this.supportedCultures.find(t => t.code == this.defaultCulture);

    constructor(public translate: TranslateService) {
        this.translate.setDefaultLang(this.defaultCulture.toString());
    }


    public loadTranslations(...args: ILocale[]): void {
        args.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translate.setTranslation(locale.lang, locale.data, true);
        });
        // Fix errata traduzione in sottomoduli
        this.translate.use(this.currentLanguage());
    }
    public currentLanguage() {
        if (localStorage.getItem('app_culture')) return localStorage.getItem('app_culture');
        var cultureCode = this.findCurrentLang().code;
        localStorage.setItem('app_culture', cultureCode);
        return cultureCode;
    }
    public findCurrentLang() {
        // nell'array delle browserLangs aggiungo anche
        // la selezione dell'utente salvata nel cookie se c'è
        var browserLangs: string[] = [];
        if (localStorage.getItem('app_culture')) browserLangs.push(localStorage.getItem('app_culture'));
        browserLangs = browserLangs.concat(navigator.languages);

        // confronto gli array
        for (let browserLang of browserLangs) {
            for (let item of this.supportedCultures) {
                var culture = <CultureModel>item;
                if (browserLang == culture.code.toString()) return culture;
                if (browserLang == culture.code.toString().slice(0, 2)) return culture;
            }
        }
        return this.defaultCultureModel;
    }
    public hasCulture(culture: En_CultureCode): boolean {
        var code = culture.toString();
        if (code.length > 2) {
            var temp1 = this.currentLanguage();
            if (code == temp1) return true;
        }
        var temp2 = this.currentLanguage();
        if (code.slice(0, 2) == temp2) return true;
        return false;
    }
    public useCulture(code: string) { //culture: En_CultureCode) {
        this.supportedCultures.forEach(item => {
            if (!(item.code.toString().toLowerCase() == code.toLowerCase()) && (item.code.toString().toLowerCase().startsWith(code.toLowerCase()))) code = item.code;
        });
        this.translate.use(code);
        localStorage.setItem('app_culture', code);
        console.info(`Nav lang: ${navigator.language} | App lang: ${this.currentLanguage()}`);
    }
}

export class CultureModel {
    public code: En_CultureCode;
    public abbrText: string;
    public text: string;
    public icon: string;
}

export enum En_CultureCode {
    de_DE = 'de-DE',
    it_IT = 'it-IT',
    en_GB = 'en-GB',
    fr_FR = 'fr-FR',
    es_ES = 'es-ES'
}
