import { ILocale } from 'src/@aran/models/locale.interface';
import { En_CultureCode } from 'src/@aran/services/translate.service';

export const locale: ILocale = {
    lang: En_CultureCode.it_IT,
    data:
    {
        KENDO: {
            NOITEMS: 'Nessun elemento disponibile',
            PAGERPAGE: 'Pagina',
            PAGEROF: 'di',
            PAGERITEMS: 'elementi',
            PAGERITEMSPERPAGE: 'elementi per pagina',
        },
        GENERIC: {
            BTN_END:"Concludi",
            BTN_TAKE: 'Prendi in carico',
            BTN_EDIT: 'Modifica',
            BTN_ADD: 'Aggiungi',
            BTN_OPEN: 'Apri',
            BTN_CLOSE: 'Chiudi',
            BTN_OK: 'Ok',
            BTN_CANCEL: 'Annulla',
            BTN_SAVE: 'Salva',
            BTN_DELETE: 'Elimina',
            BTN_CONFIRM: 'Conferma',
            BTN_BACK: 'Indietro',
            BTN_NEXT: 'Avanti',
            BTN_GO: 'Vai',
            BTN_REMOVE: 'Rimuovi',
            BTN_REFRESH: 'Aggiorna',
            BTN_CLEAN: 'Pulisci',
            BTN_APPROVE: 'Approva',
            BTN_DECLINE: 'Rifiuta',
            BTN_ACCEPTED: 'Accettato',
            BTN_DECLINED: 'Rifiutato',
            BTN_SEND: 'Invia',
            BTN_LOGIN: 'Entra',
            RETRY: 'Riprova',
            WARNING: 'Attenzione',
            HTTP_ERROR: 'Errore durante l\'operazione',
            YES: 'Si',
            NO: 'No',
            NO_DATA: 'Nessun dato presente',
            MONTH: 'mese',
            MONTHS: 'mesi',
            ERROR: 'Errore',
            ACTION_SUCCESS: 'Operazione effettuata correttamente.',
            ACTION_FAIL: 'Si è verificato un errore durante l\'operazione.'
        }
    }
};