import { Injectable } from '@angular/core';

export interface ICookie {
    name: string;
    expiringDays: number;
    path: string;
    secure: boolean;
    sameSite: string;
}

@Injectable({ providedIn: 'root' })
export class AranCookieService {
    public getCookieValueFromName(name: string): string {
        let cookies: string[] = document.cookie.split(';');
        let cookieName = `${name}=`;
        let c: string;
        for (let i = 0; i < cookies.length; i++){
            c = cookies[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0){
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    public setCookie(name: string, value: string, expiringDays?: number, path?: string, secure: boolean = true){
        if (!expiringDays || expiringDays == undefined || expiringDays == null) { expiringDays = 1; }
        let d: Date = new Date();
        d.setTime(d.getTime() + expiringDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        if (!path || path == undefined || path == null) { path = ''; }
        else { path = `;path=${path}`; }
        document.cookie = `${name}=${value}; ${expires}${path}`; //${(secure ? ';secure' : '')}
    }

    public deleteCookie(name) { this.setCookie(name, '', -1); }
}