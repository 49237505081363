import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { auth_token_key, getTokenExpDateFromUTCOffset, renew_auth_token_key, TokenBase } from './token.service';

@Injectable()
export class BaseHttpService {

    constructor(private _http: HttpClient) { }


    /**
     * Incapsula una chiamata HTTP GET del service base angular includendo il token di autenticazione
     * @param url
     * @param options
     */
    public secureHttpGet(url: string, queryStringData?: any, options?: any): Observable<any> {
        this.tokenRenew();
        options = this.addHeader(options, queryStringData);
        var _URL: string;
        if (url.startsWith("http:") || url.startsWith("https:")) { _URL = url; } else { _URL = environment.apiURI + url; }
        return this._http.get(_URL, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP POST del service base angular includendo il token di autenticazione
     * @param relativeUrl
     * @param body
     * @param options
     */
    public secureHttpPost(relativeUrl: string, body: any, options?: any): Observable<any> {
        this.tokenRenew();
        options = this.addHeader(options);
        var _URL: string;
        if (relativeUrl.startsWith("http:") || relativeUrl.startsWith("https:")) { _URL = relativeUrl; } else { _URL = environment.apiURI + relativeUrl; }
        return this._http.post(_URL, body, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP POST del service base angular accettando come body un FormData includendo il token di autenticazione
     * @param relativeUrl
     * @param body
     * @param options
     */
    public secureHttpFormPost(relativeUrl: string, body: FormData, options?: any): Observable<any> {
        this.tokenRenew();
        options = this.addHeader(options, null, false);
        var _URL: string;
        if (relativeUrl.startsWith("http:") || relativeUrl.startsWith("https:")) { _URL = relativeUrl; } else { _URL = environment.apiURI + relativeUrl; }
        return this._http.post(_URL, body, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP PUT del service base angular includendo il token di autenticazione
     * @param relativeUrl
     * @param body
     * @param options
     */
    public secureHttpPut(method: string, body?: any, options?: any): Observable<any> {
        this.tokenRenew();
        options = this.addHeader(options);
        return this._http.put(environment.apiURI + method, body, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP DELETE del service base angular includendo il token di autenticazione
     * @param relativeUrl
     * @param options
     */
    public secureHttpDelete(url: string, queryStringData?: any, options?: any): Observable<any> {
        this.tokenRenew();
        options = this.addHeader(options, queryStringData);
        return this._http.delete(environment.apiURI + url, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }



    /**
     * Incapsula una chiamata HTTP GET del service base angular
     * @param relativeUrl
     * @param options
     */
    public baseHttpGet(relativeUrl: string, options?: any): Observable<any> {
        options = this.addHeader(options, null, true, false);
        return this._http.get(environment.apiURI + relativeUrl, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP POST del service base angular
     * @param relativeUrl
     * @param body
     * @param options
     */
    public baseHttpPost(relativeUrl: string, inputObj: any, options?: any): Observable<any> {
        options = this.addHeader(options, null, true, false);
        return this._http.post(environment.apiURI + relativeUrl, inputObj, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP POST del service base angular
     * @param relativeUrl
     * @param body
     * @param options
     */
    public baseHttpFormPost(relativeUrl: string, inputObj: FormData, options?: any): Observable<any> {
        options = this.addHeader(options, null, true, false);
        return this._http.post(environment.apiURI + relativeUrl, inputObj, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP PUT del service base angular
     * @param relativeUrl
     * @param body
     * @param options
     */
    public baseHttpPut(relativeUrl: string, inputObj: any, options?: any): Observable<any> {
        options = this.addHeader(options, null, true, false);
        return this._http.put(environment.apiURI + relativeUrl, inputObj, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP DELETE del service base angular
     * @param relativeUrl
     * @param options
     */
    public baseHttpDelete(relativeUrl: string, options?: any): Observable<any> {
        options = this.addHeader(options, null, true, false);
        return this._http.delete(environment.apiURI + relativeUrl, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Incapsula una chiamata HTTP PATCH del service base angular
     * @param relativeUrl
     * @param body
     * @param options
     */
    public baseHttpPatch(relativeUrl: string, inputObj: any, options?: any): Observable<any> {
        options = this.addHeader(options, null, true, false);
        return this._http.patch(environment.apiURI + relativeUrl, inputObj, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    /**
     * Restituisce la coppia chiave valore da aggiungere nell'header dell'upload automatico kendo
     */
    // TODO A16: Viene usata questa funzione? Può essere scritta meglio
    public baseHttpHeadersUploadDocument(): { key: string, value: string } {
        this.tokenRenew();
        let tkn = localStorage.getItem(auth_token_key);
        return { key: 'Authorization', value: `Bearer ${tkn}` };
    }

    public async downloadFileSecure(method: string, options?: any): Promise<Blob> {
        this.tokenRenew();
        options = this.addHeader(options, false);
        const file = await this._http.get<Blob>(environment.apiURI + method,
            { headers: options.headers, responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    public async downloadFilePostFormSecure(method: string, inputObj: FormData, options?: any): Promise<Blob> {
        this.tokenRenew();
        options = this.addHeader(options, false);
        const file = await this._http.post<Blob>(environment.apiURI + method, inputObj,
            { headers: options.headers, responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    public async downloadFilePostSecure(method: string, inputObj: any, options?: any): Promise<Blob> {
        this.tokenRenew();
        options = this.addHeader(options, false);
        const file = await this._http.post<Blob>(environment.apiURI + method, inputObj,
            { headers: options.headers, responseType: 'blob' as 'json' }).toPromise();
        return file;
    }

    public getTextToSpeechToken(uri: string, subKey: string) {
        let options = { headers: { 'Ocp-Apim-Subscription-Key': subKey }, responseType: 'text' as 'json' };
        return this._http.post(uri, null, options).pipe(
            map(response => response),
            catchError(this.handleError));
    }

    private tokenRenew() {
        let renewTokenIsRunning = localStorage.getItem(renew_auth_token_key);

        if (!renewTokenIsRunning || renewTokenIsRunning == 'false') {

            const tokenString = localStorage.getItem(auth_token_key);
            if (!tokenString || tokenString.length <= 0) {
                return;
            }

            var dotIndex1 = tokenString.indexOf('.');
            if (dotIndex1 > 0) dotIndex1++;
            var dotIndex2 = tokenString.lastIndexOf('.');
            var midToken = tokenString.substring(dotIndex1, dotIndex2);

            const tokenObj: TokenBase = <TokenBase>JSON.parse(atob(midToken));
            const expirationDate = getTokenExpDateFromUTCOffset(tokenObj.exp);
            const now = new Date();

            //console.log(expirationDate);

            if (now >= expirationDate) {
                localStorage.removeItem(auth_token_key);
                return;
            }

            now.setUTCSeconds(now.getSeconds() + (60 * 20)); //tempo entro cui il token viene rinnovato alla prima azione

            if (now >= expirationDate) {
                localStorage.setItem(renew_auth_token_key, 'true');
                let options = this.addHeader();
                new Promise((resolve, reject) => {
                    this._http.get(environment.apiURI + '/authentication/token-renew', options)
                        .pipe(map(response => response), catchError(this.handleError))
                        .subscribe(res => resolve(res), err => reject(err))
                }).then((res: any) => {
                    if (res && res.token && res.token.length > 0) {
                        localStorage.setItem(auth_token_key, res.token);
                        console.info('Token renewed');
                    }
                    else {
                        localStorage.removeItem(auth_token_key);
                    }
                }).finally(() => {
                    localStorage.removeItem(renew_auth_token_key);
                });
            }
        }


    }

    private addHeader(options?: any, queryStringData: any = null, addJsonContentType: boolean = true, withBearerToken: boolean = true, addHeaderCulture: boolean = true): any {
        let httpOptions = { headers: new HttpHeaders(), params: new HttpParams() };
        if (options) httpOptions = options;

        if (httpOptions && !httpOptions.headers) httpOptions.headers = new HttpHeaders();
        if (addJsonContentType) httpOptions.headers = httpOptions.headers.append('Content-Type', `application/json`);
        if (withBearerToken) httpOptions.headers = httpOptions.headers.append('Authorization', `Bearer ${localStorage.getItem(auth_token_key)}`);
        if (addHeaderCulture && localStorage.getItem('app_culture')) httpOptions.headers = httpOptions.headers.append('Culture', localStorage.getItem('app_culture'));

        if (httpOptions && !httpOptions.params) httpOptions.params = new HttpParams();
        if (queryStringData && queryStringData != null) httpOptions.params = new HttpParams({ fromObject: queryStringData });

        return httpOptions;
    }
    protected handleError(error: any) {
        console.error('HttpClient custom error handler', error);

        if (error.status && error.status == 401) {
            // TODO <A16> Doremmo richiamare la SessionService Logout ma non possiamo perche altrimenti si fa loop
            // quindi risolvo facendo le cosine a manino
            localStorage.removeItem(auth_token_key);
            console.warn('Utente non autorizzato');
            location.href = "/";
            return;
            //accesso negato!
            //this._http.get(environment.apiURI + '/auth/ping', this.addhHeader(null)).map(response => response)
            //    .catch(this.handleError401);
        }

        // either applicationError in header or model error in body
        var applicationError = error.headers.get('Application-Error');
        if (applicationError) return observableThrowError(applicationError);

        var modelStateErrors: string = '';
        var serverError = JSON.parse(JSON.stringify(error));

        if (!serverError.type) {
            for (var key in serverError) {
                if (serverError[key])
                    modelStateErrors += serverError[key] + '\n';
            }
        }

        //if (error.status === 400 && error._body) {
        //    var responseError = JSON.parse(error._body);
        //    if (!responseError) {
        //        for (var key in responseError) {
        //            if (responseError[key])
        //                modelStateErrors += responseError[key] + '\n';
        //        }
        //    }
        //}

        // .NET Exception message
        if (error.error)
            if (error.error.Message)
                modelStateErrors = error.error.Message;

        // Generic message
        if (!modelStateErrors && error.message)
            modelStateErrors = error.message;

        modelStateErrors = modelStateErrors == '' ? null : modelStateErrors;
        return observableThrowError(modelStateErrors || 'Server error');
    }
}
