import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login2FAViewModel } from '../models/login2FA.interface';
import { EmailConfirmModel, ForgotPasswordModel, LoginModel, LoginPINModel, LoginResponseModel, ResetPasswordModel, UserModel, UserPublicModel } from '../models/session.model';
import { BaseHttpService } from './http.service';

@Injectable()
export class AranSessionDataService {
    constructor(private _baseHttp: BaseHttpService) { }

    //#region LOGIN / LOGOUT
    public login(model: LoginModel): Observable<LoginResponseModel> { return this._baseHttp.baseHttpPost(`/authentication/login`, model); }
    public login2FA(model: Login2FAViewModel): Observable<LoginResponseModel> { return this._baseHttp.baseHttpPost(`/authentication/login2FA`, model); }
    public loginPIN(model: LoginPINModel): Observable<LoginResponseModel> { return this._baseHttp.baseHttpPost(`/authentication/loginPIN`, model); }
    public logout(): Observable<any> { return this._baseHttp.secureHttpGet(`/authentication/logout`); }
    //#endregion

    public forgotPassword(model: ForgotPasswordModel) { return this._baseHttp.secureHttpPost(`/authentication/forgot-password`, model); }
    public resetPassword(model: ResetPasswordModel) { return this._baseHttp.secureHttpPost(`/authentication/reset-password`, model); }
    public emailConfirm(model: EmailConfirmModel) { return this._baseHttp.secureHttpPost(`/authentication/email-confirm`, model); }

    public getMe(): Observable<UserModel> { return this._baseHttp.secureHttpGet(`/authentication/getMe`); }
    public getUserPublic(userID: number): Observable<UserPublicModel> { return this._baseHttp.secureHttpGet(`/authentication/getUserPublic/${userID}`); }
    public changeAvatar(avatarFile) { let formData: FormData = new FormData(); formData.append('imgfile', avatarFile); return this._baseHttp.secureHttpFormPost(`/authentication/change-avatar`, formData); }
    public getMyAnagLinkAndRoles(): Observable<any> { return this._baseHttp.secureHttpGet(`/authentication/getmyanaglinkandroles`); }

}
