export class UserModel {
    id: number = null;   //ATTENZIONE: per motivi di sicurezza questo dato dovrebbe essere recuperato dal token
    culture: string = null;
    email: string = null;
    emailConfirmed: string = null;
    userName: string = null;
    passwordExpirationDateTime: string = null;
    phoneNumber: string = null;
    phoneNumberConfirmed: string = null;
    twoFactorEnabled: string = null;
    twoFactorTokenProviders: string = null;
    privacyAcceptanceDateTime: string = null;
    policyAcceptanceDateTime: string = null;
    //userAnagID: string = null;  //ATTENZIONE: per motivi di sicurezza questo dati deve essere recuperato dal token
    userAnagName: string = null;
    userAnagSurname: string = null;
    userAnagAvatar: string = null;
    //public userRoleAnagID: string;
    public userRoleAnagName: string;
    public userRoleAnagAvatar: string;
    public isMale: boolean;
    public userAnagTaxCode: string;
    constructor() { }
}

export class UserPublicModel {
    completeName: string = null;
    abbreviationName: string = null;
    avatar: string = null;

    constructor() { }
}

export class LoginModel {
    public username: string = null;
    public password: string = null;
    public culture: string = null;
    public rememberMe: boolean = false;
    public rememberValue: string = null;

    constructor() { }
}

export class LoginPINModel {
    public token: string = null;
    public pin: string = null;
    public culture: string = null;

    constructor() { }
}

export class ForgotPasswordModel {
    public email: string = null;

    constructor() { }
}

export class ResetPasswordModel {
    public token: string = null;
    public email: string = null;
    public password: string = null;
    public confirmPassword: string = null;
    public code: string = null;

    constructor() { }
}

export class EmailConfirmModel {
    public userID: string = null;
    public token: string = null;

    constructor() { }
}

export class LoginResponseModel {
    public message: string = null;
    public needRoleSelection: boolean = true;
    public token: string = null;
    public token2FA: string = null;
    public token2FAProvider: "Email"
    public tokenRecoverPassword: string = null;

    constructor() { }
}

export class PasswordExpiredModel {
    public token: string;
    public oldPassword: string;
    public newPassword: string;
    public confirmPassword: string;
}


export class AccountDTO {
    public userId: number = 0;
    public username: string = null;
    public userEmail: string = null;
    public userEmailConfirmed: boolean = false;
    public userMobile: string = null;
    public userMobileConfirmed: boolean = false;
    public userDisabled: boolean = true;
    public userRole: string = null;
    public culture: string = null;
    public ang_ID: number = 0;
    public ang_PersonName: string = null;
    public ang_PersonSurname: string = null;
    public ang_TaxCode: string = null;
    public ang_IdentificationCode: string = null;
    public ang_Avatar: string = null;
    public lastAccessDateTime?: Date = null;
}