import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AranSessionService } from 'src/@aran/services/session.service';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    //template: `<router-outlet></router-outlet>`,
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    constructor(
        private _router: Router,
        private _actRoute: ActivatedRoute,
        private _platform: Platform,
        public _sessionService: AranSessionService,
        @Inject(DOCUMENT) private document: any,
    ) {
        // Aggiunta classe css nel body per detect device mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.className += ' is-mobile';
        }
    }
    ngOnInit() { }
}
