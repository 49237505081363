import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login2FAViewModel } from '../models/login2FA.interface';
import { EmailConfirmModel, ForgotPasswordModel, LoginModel, LoginPINModel, ResetPasswordModel, UserModel, LoginResponseModel } from '../models/session.model';
import { AranCookieService } from './cookie.service';
import { BaseHttpService } from './http.service';
import { AranSessionDataService } from './session.data.service';
import { auth_token_key, getTokenExpDateFromUTCOffset, renew_auth_token_key, TokenBase } from './token.service';

@Injectable({ providedIn: 'root' })
export class AranSessionService {

    public me: UserModel = null;
    public login2FAmodel: Login2FAViewModel = null;

    public dueDiligenceID: string;

    //public applicationResources: ApplicationResourcesModel;
    @Output() public onUserLogin: EventEmitter<any> = new EventEmitter(null);
    @Output() public onUserUpdate: EventEmitter<any> = new EventEmitter(null);
    @Output() public onUserLogout: EventEmitter<any> = new EventEmitter(null);
    @Output() public userAvatarEmitter: EventEmitter<boolean> = new EventEmitter(null);

    constructor(
        private _httpService: BaseHttpService,
        private _sessionDataService: AranSessionDataService,
        private _cookieService: AranCookieService,
        private _router: Router
    ) {
        if (this.isLoggedIn()) {
            this.onUserLogin.emit({ token: this.getToken() });
            console.info('onUserLogin emitted');
        }
    }


    //#region ========== AUTHENTICATION API ==========
    public login(username: string, password: string, culture: string, rememberMe: boolean, rememberValue: string = ''): Promise<string[]> {
        return new Promise((resolve, reject) => {
            var model = new LoginModel();
            model.username = username;
            model.password = password;
            model.culture = culture;
            model.rememberMe = rememberMe;
            model.rememberValue = rememberValue;
            this._sessionDataService.login(model).subscribe(
                res => {
                    if (res.token2FA) {
                        this.login2FAmodel = new Login2FAViewModel();
                        this.login2FAmodel.userName = res.token;
                        this.login2FAmodel.twoFactorProvider = res.token2FAProvider;
                        resolve(['/login/2FA']);
                    }
                    /*  else if (res.message === 'PASSWORD_EXPIRED') {
                         this.saveToken(res.token);
                         resolve(['/login/change-password']);
                     } */
                    else if (res.token) {
                        this.saveToken(res.token);
                        //check ruoli anagrafica
                        this._sessionDataService.getMyAnagLinkAndRoles()
                            .subscribe(
                                res => {
                                    if (res.token != null && res.token != '' && (res.anagLink == null || res.anagLink.length == 1)) {
                                        this.saveToken(res.token);
                                        resolve(this.getUserHome());
                                    }
                                    else {
                                        resolve(['/login/select-role']);
                                    }
                                },
                                err => { reject(err); });
                    }
                },
                err => { reject(err); });
        });
    }
    public login2FA(model: Login2FAViewModel): Promise<string[]> {
        return new Promise((resolve, reject) => {
            this._sessionDataService.login2FA(model).subscribe(
                res => {
                    /* if (res.message === 'PASSWORD_EXPIRED') {
                        resolve(['/login/change-password']);
                    }
                    else */ if (res.token) {
                        this.saveToken(res.token);
                        this.getMe().then(usr => {
                            if (model.rememberMe) {
                                this._cookieService.setCookie(
                                    environment.rememberMachine.name,
                                    `${usr.id}`,
                                    environment.rememberMachine.expiringDays,
                                    environment.rememberMachine.path,
                                    environment.rememberMachine.secure);
                            }
                            //check ruoli anagrafica
                            this._sessionDataService.getMyAnagLinkAndRoles()
                                .subscribe(
                                    res => {
                                        if (res.token != null && res.token != '' && (res.anagLink == null || res.anagLink.length == 1)) {
                                            this.saveToken(res.token);
                                            resolve(this.getUserHome());
                                        }
                                        else {
                                            resolve(['/login/select-role']);
                                        }
                                    },
                                    err => { reject(err); });
                        });
                    }
                },
                err => reject(err));
        });
    }
    public loginPIN(token: string, pin: string, culture: string): Promise<any> {
        return new Promise((resolve, reject) => {
            var model = new LoginPINModel();
            model.token = token;
            model.pin = pin;
            model.culture = culture;
            this._sessionDataService.loginPIN(model).subscribe(
                res => { resolve(res); },
                err => { reject(err); });
        });
    }
    public loginSelectRole(model: Login2FAViewModel): Promise<string[]> {
        return new Promise((resolve, reject) => {
            this._sessionDataService.login2FA(model).subscribe(
                res => {
                    if (res.token) {
                        this.saveToken(res.token);
                        this.getMe().then(usr => {
                            if (model.rememberMe) {
                                this._cookieService.setCookie(
                                    environment.rememberMachine.name,
                                    `${usr.id}`,
                                    environment.rememberMachine.expiringDays,
                                    environment.rememberMachine.path,
                                    environment.rememberMachine.secure);
                            }
                            this.getMe().then(usr => { resolve(this.getUserHome()); });
                        });
                    }
                },
                err => reject(err));
        });
    }
    public logout(): void {
        new Promise((resolve, reject) => {
            this._sessionDataService.logout().subscribe(
                res => { resolve(res); },
                err => { reject(err); })
        })
            .then(() => { })
            .finally(() => {
                this.me = null;
                this.forgotToken();
                this.onUserLogout.emit();
                //Reload Angular to refresh components and prevent old data from loading up for a 
                //another user after login. This especially applies lazy loading cases. 
                window.location.href = window.location.href.split('?')[0] + '?' + new Date().getMilliseconds();
                //this._router.navigate(['/', 'login']);
            });
    }
    //#endregion

    public forgotPassword(model: ForgotPasswordModel) {
        return new Promise((resolve, reject) => {
            this._sessionDataService.forgotPassword(model).subscribe(res => resolve(res), err => reject(err));
        });
    }
    public resetPassword(model: ResetPasswordModel) {
        return new Promise((resolve, reject) => {
            this._sessionDataService.resetPassword(model).subscribe(res => resolve(res), err => reject(err));
        });
    }
    public emailConfirm(model: EmailConfirmModel) {
        return new Promise((resolve, reject) => {
            this._sessionDataService.emailConfirm(model).subscribe(
                res => { resolve(['/email-confirmed']); },
                err => { reject(err); });
        });
    }

    public isPasswordExpierd(): boolean {
        return (
            this.me &&
            this.me.passwordExpirationDateTime &&
            this.me.passwordExpirationDateTime.length > 0 &&
            new Date(this.me.passwordExpirationDateTime) <= new Date());
    }
    public isLoggedIn(): boolean {
        var tokenString = this.getToken();
        if (!tokenString || tokenString.length <= 0) {
            return false;
        }

        var dotIndex1 = tokenString.indexOf('.');
        if (dotIndex1 > 0) dotIndex1++;
        var dotIndex2 = tokenString.lastIndexOf('.');
        var midToken = tokenString.substring(dotIndex1, dotIndex2);

        const tokenObj: TokenBase = <TokenBase>JSON.parse(atob(midToken));
        const expirationDate = getTokenExpDateFromUTCOffset(tokenObj.exp);
        const now = new Date();

        if (now >= expirationDate) {
            localStorage.removeItem(auth_token_key);
            localStorage.removeItem(renew_auth_token_key);
            return false;
        }
        return true;
    }
    public getToken(): string { return localStorage.getItem(auth_token_key); }
    public getTokenContent(cToken: string = null): any {
        if (cToken == null) cToken = this.getToken();
        if (!cToken || isNullOrEmptyString(cToken) || cToken.length == 0) return null;
        var dotIndex1 = cToken.indexOf('.');
        if (dotIndex1 > 0) dotIndex1++;
        var dotIndex2 = cToken.lastIndexOf('.');
        var midToken = cToken.substring(dotIndex1, dotIndex2);
        return JSON.parse(atob(midToken));
    }
    public saveToken(val: any): void {
        localStorage.setItem(auth_token_key, val);
        localStorage.removeItem(renew_auth_token_key);
    }
    public forgotToken(): void {
        localStorage.removeItem(auth_token_key);
        localStorage.removeItem(renew_auth_token_key);
    }

    public hasRole(role: string): boolean {
        const key = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
        var tokenObj = this.getTokenContent();
        if (tokenObj[key] && tokenObj[key] == role) return true;
        if (tokenObj[key] && tokenObj[key].indexOf(role) >= 0) return true;
        if (tokenObj['UserClaimsRole'] && tokenObj['UserClaimsRole'] == role) return true;
        if (tokenObj['UserClaimsRole'] && tokenObj['UserClaimsRole'].indexOf(role) >= 0) return true;
        return false;
    }
    public hasClaim(claimType: string, claimValue: string = '1'): boolean {
        var tokenObj = this.getTokenContent();
        if (!tokenObj) return false;
        if (tokenObj['GOD'] && tokenObj['GOD'] == '1') return true;
        if (tokenObj[claimType] && tokenObj[claimType] == claimValue) return true;
        return false;
    }
    public getClaimValue(claimType: string): string {
        var tokenObj = this.getTokenContent();
        if (!tokenObj) return null;
        return tokenObj[claimType];
    }
    public getUserAvatar(userID: number): Observable<any> {
        return this._httpService.secureHttpGet(`/profile/getAvatar/${userID}`);
    }



    //public pinUrl(redirectTo: string): string {
    //    if (this.me.pinToken != null && this.me.pinToken != '') {
    //        var baseurl = location.protocol + '//' + location.hostname;
    //        if (location.port != null && location.port != '') { baseurl = baseurl + ':' + location.port; }
    //        let fromUrl = '?redirectTo=' + redirectTo;
    //        //console.log(fromUrl);
    //        return (this.me && this.me.pinToken && this.me.pinToken != '') ? baseurl + '/login/login-pin/' + this.me.pinToken + fromUrl : baseurl + '/login';
    //    }
    //    else { return ''; }
    //}



    public getMe(): Promise<UserModel> {
        return new Promise((resolve, reject) => {
            this._sessionDataService.getMe().subscribe(
                res => { this.me = res; resolve(res); },
                err => { this.handleError(err); reject(err); });
        })
    }

    public getMyAnagLinkAndRoles(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._sessionDataService.getMyAnagLinkAndRoles().subscribe(
                res => {
                    if (res.token) {
                        this.saveToken(res.token);
                        this.getMe().then(usr => {
                            resolve(this.getUserHome());
                        });
                    }
                },
                err => { reject(err); });
        })
    }

    //public getMeByToken(token: string): Promise<UserViewModel> {
    //    return new Promise((resolve, reject) => {
    //        this._sessionDataService.getMeByToken(token).subscribe(
    //            res => { this.me = res; resolve(res); },
    //            err => { this.handleError(err); reject(err); });
    //    })
    //}

    public getUserPublic(userID: number) {
        return new Promise((resolve, reject) => {
            this._sessionDataService.getUserPublic(userID).subscribe(res => resolve(res), err => reject(err));
        });
    }

    public changeAvatar(avatarFile: any) {
        return new Promise((resolve, reject) => {
            this._sessionDataService.changeAvatar(avatarFile).subscribe(
                (res) => { this.getMe(); resolve(res); this.userAvatarEmitter.emit(true); },
                (err) => { reject(err); });
        });
    }

    //#region PRIVATE
    public getUserHome(): string[] {
        if (this.hasClaim('BACKOFFICE') || this.hasClaim('GOD')) this.logintobackoffice();
        else if (this.hasClaim('CUSTOMER')) return ['/', 'customer-area'];
        else { console.error('User home not found'); }
        return null;
    }

    //#loginTo BACKOFFICE
    public logintobackoffice() {
        new Promise((resolve, reject) => {
            this._httpService.secureHttpGet(`${environment.backofficeURI}Authentication/loginIntoSession`).subscribe(
                (res) => { window.location.replace(environment.backofficeURI); },
                (err) => { reject(err); });
        });
    }


    private handleError(err) { this.logout(); }
    //#endregion
}
